import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ref, onValue, update, push } from 'firebase/database';
import { analytics, database, storage } from '../components/firebaseConfig'; // Adjust the path according to your project structure
import { logEvent } from 'firebase/analytics';
import { Helmet } from 'react-helmet-async';
import Chipview from '../components/Chipview';
import { getAuth } from 'firebase/auth';
import { getDownloadURL, ref as storageRef, uploadBytesResumable } from 'firebase/storage';

const ProjectDetailsPage = () => {
  const { projectId } = useParams();
  const [project, setProject] = useState(null);
  const [updates, setUpdates] = useState([]);
  const [newUpdate, setNewUpdate] = useState({ title: '', description: '', date: new Date().toISOString() });
  const [isAdmin, setIsAdmin] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [bannerFile, setBannerFile] = useState(null);
  const [bannerUploadProgress, setBannerUploadProgress] = useState(0);
  const { currentUser } = getAuth();

  useEffect(() => {
    const checkAdmin = () => {
      setIsAdmin(process.env.REACT_APP_EMAIL === getAuth()?.currentUser?.email);
    };

    checkAdmin();
  }, [currentUser]);

  function submit(projectLink) {
    window.open(projectLink, '_blank');
  }

  const handleUpdateChange = (e) => {
    const { name, value } = e.target;
    setNewUpdate(prevUpdate => ({ ...prevUpdate, [name]: value }));
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedProject(prevProject => ({ ...prevProject, [name]: value }));
  };

  const handleBannerChange = (e) => {
    const file = e.target.files[0];
    setBannerFile(file);
  };

  const uploadBannerImage = async () => {
    if (!bannerFile) return;

    const bannerStorageRef = storageRef(storage, `banners/${projectId}`);
    const uploadTask = uploadBytesResumable(bannerStorageRef, bannerFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setBannerUploadProgress(progress);
      },
      (error) => {
        console.error('Error uploading banner:', error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setEditedProject((prevProject) => ({
            ...prevProject,
            banner: downloadURL,
          }));
        });
      }
    );
  };

  const handleSaveProject = async () => {
    if (bannerFile) {
      await uploadBannerImage();
    }
    const projectRef = ref(database, `projects/${projectId}`);
    await update(projectRef, editedProject);
    setEditMode(false);
  };

  const handleSubmitUpdate = async () => {
    const updatesRef = ref(database, `projects/${projectId}/updates`);
    const newUpdateKey = push(updatesRef);
    
    await update(newUpdateKey, {
        title: newUpdate.title,
        description: newUpdate.description,
        date: newUpdate.date
      }
    );

    setNewUpdate({ title: '', description: '', date: new Date().toISOString() });
  };

  useEffect(() => {
    const projectRef = ref(database, `projects/${projectId}`);
    const updatesRef = ref(database, `projects/${projectId}/updates`); // Assuming updates are stored under 'updates' in the project

    onValue(projectRef, (snapshot) => {
      const projectData = snapshot.val();
      setProject(projectData);
      // console.log(JSON.stringify(projectData).toString())
      setEditedProject(projectData); // Initialize editedProject with the fetched data
    });

    onValue(updatesRef, (snapshot) => {
      const updatesData = snapshot.val() || [];
      setUpdates(Object.values(updatesData));
    });

    if (project) {
      logEvent(analytics, `Viewed Project ${project.name}`, {
        firebase_screen: `Project View ${project.name}`,
        firebase_screen_class: `viewprojects ${project.name}`
      });
    }
  }, []);

  if (!project) {
    return <div className='flex min-h-screen font-extrabold justify-center items-center'>Loading...</div>;
  }

  return (
    <div className="bg-white min-h-screen">
      <Helmet>
        <title>{project.name} | Dailycashloot - Task & Crypto</title>
        <meta name="description" content={project.description} />
        <meta name="keywords" content={`${project.category}, what is ${project.name}, how to create a account in ${project.name}, why to choose ${project.name}`} />
      </Helmet>

      <div className="mx-auto shadow-lg">
        {/* Project Details Section */}
        <div className="relative p-6">
          <div className="w-full h-32">
            {!editMode ? (
              <img
                draggable="false"
                src={project.banner || "https://via.placeholder.com/1500x500"}
                alt={`${project.name} Logo`}
                className="w-full h-full object-cover rounded-lg"
              />
            ) : (
              <div>
                <input
                  type="file"
                  onChange={handleBannerChange}
                  className="w-full mb-2"
                />
                {bannerUploadProgress > 0 && (
                  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4">
                    <div
                      className="bg-blue-600 h-2.5 rounded-full"
                      style={{ width: `${bannerUploadProgress}%` }}
                    ></div>
                  </div>
                )}
              </div>
            )}
          </div>
          <div
            className="absolute top-14 mt-14 left-10 rounded-full overflow-hidden mr-4 flex-shrink-0 border-4 border-white"
            style={{ width: '80px', height: '80px' }}
          >
            <img
              draggable="false"
              src={project.imageUrl}
              alt={project.name}
              className="w-full h-full object-cover rounded-full"
            />
          </div>
          {!editMode ? (
            <div className="flex items-center">
              <div>
                <h1 className="flex text-3xl font-bold mt-10">
                  {project.name}
                  {project.verified && (
                    <img
                      draggable="false"
                      className="h-8 w-8 ml-2"
                      src={`${process.env.PUBLIC_URL}/verifiedicon.png`}
                      alt="Verified Icon"
                    />
                  )}
                </h1>
                {project.new && (
                  <h1 className="flex justify-center w-16 rounded-full bg-green-400 text-xs text-white px-2 py-1 mt-2">
                    New ✨
                  </h1>
                )}
              </div>
            </div>
          ) : (
            <div className="flex items-center">
              <input
                type="text"
                name="name"
                value={editedProject.name}
                onChange={handleEditChange}
                placeholder="Project Name"
                className="text-3xl font-bold mt-10 w-full"
              />
            </div>
          )}
          {!editMode ? (
            <p className="text-gray-500 my-4">{project.description}</p>
          ) : (
            <textarea
              name="description"
              value={editedProject.description}
              onChange={handleEditChange}
              placeholder="Project Description"
              className="w-full p-2 my-4 border border-gray-300 rounded"
              rows="4"
            />
          )}
          <div className="grid grid-cols-2 gap-4 mb-6">
            {project.category && (
              <div>
                <p className="text-sm font-semibold mb-3 text-gray-700">
                  Category
                </p>
                {!editMode ? (
                  <div className="text-sm mb-2 font-semibold">
                    <Chipview categories={project.category} />
                  </div>
                ) : (
                  <input
                    type="text"
                    name="category"
                    value={editedProject.category}
                    onChange={handleEditChange}
                    placeholder="Category"
                    className="text-sm mb-2 font-semibold w-full"
                  />
                )}
              </div>
            )}
          </div>
          <div className="flex justify-between items-center">
            <div className="text-sm text-gray-500">
              Approved: {project.approved ? 'Yes' : 'No'}
            </div>
            <button
              onClick={() => submit(project.link)}
              className="bg-blue-500 text-white text-right px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Visit Project
            </button>
          </div>
          <div className="bg-red-100 hover:bg-red-200 rounded-lg p-4 my-4 text-sm font-sans text-gray-600">
            <span className="underline">Launchpad Disclaimer:</span> Projects
            featured on the Dailycashloot Launchpad are provided as-is without
            any warranties or guarantees. Participation in these projects
            involves risk, and Dailycashloot does not endorse, verify, or
            validate the information or offerings of these projects. Users are
            advised to conduct their own research and due diligence before
            engaging in any transactions or contributions. Dailycashloot is not
            responsible for any losses or damages incurred as a result of
            participating in Launchpad projects.
          </div>
          {isAdmin && (
            <button
              onClick={() => setEditMode(!editMode)}
              className="bg-yellow-500 text-white text-right px-4 py-2 rounded-md hover:bg-yellow-600"
            >
              {editMode ? 'Cancel Edit' : 'Edit Project'}
            </button>
          )}
          {editMode && (
            <div className="mt-4">
              <button
                onClick={handleSaveProject}
                className="bg-green-500 text-white text-right px-4 py-2 rounded-md hover:bg-green-600"
              >
                Save Changes
              </button>
            </div>
          )}
        </div>

        {/* Updates Section */}
        <div className="p-6 bg-white">
          <h2 className="text-xl font-semibold mb-4">Project Updates</h2>
          {updates.length > 0 ? (
            updates.map((update, index) => (
              <div key={index} className="bg-gray-100 p-4 rounded-lg mb-4">
                <h3 className="text-lg font-semibold">{update.title}</h3>
                <p className="text-sm text-gray-500">{new Date(update.date).toLocaleString("en-IN")}</p>
                <p className="text-sm text-gray-700">{update.description}</p>
              </div>
            ))
          ) : (
            <p className="text-gray-500">No updates available.</p>
          )}

          {isAdmin && (
            <div className="p-4 mt-4 bg-gray-200 rounded-lg">
              <h3 className="text-lg font-semibold mb-2">Add a New Update</h3>
              <input
                type="text"
                name="title"
                value={newUpdate.title}
                onChange={handleUpdateChange}
                placeholder="Update Title"
                className="w-full p-2 mb-2 border border-gray-300 rounded"
              />
              <textarea
                name="description"
                value={newUpdate.description}
                onChange={handleUpdateChange}
                placeholder="Update Description"
                className="w-full p-2 mb-2 border border-gray-300 rounded"
                rows="4"
              />
              <button
                onClick={handleSubmitUpdate}
                className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
              >
                Submit Update
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectDetailsPage;
