import { ethers } from 'ethers';
import React, { useEffect, useState } from 'react';
import { DCLCAddress } from '../components/utils/constants';
import DCLCAbi from "../components/contracts/DCLCAbi.json";

const ClaimPage = ({ props }) => {
  const { account, requestAccounts, connecting, wallet, connectedWallets, setChainID, ChainID } = props;
  const [tokenBalance, setTokenBalance] = useState(0);
  const [dclcPoints, setDclcPoints] = useState(0);

  const fetchData = async () => {
    try {
      if (account && wallet && connectedWallets?.length > 0) {
        const { provider, accounts, chains } = connectedWallets[0];
        const ChainIDS = chains[0]?.id;

        if (ChainIDS !== '0x38') {
          // Switch to BNB chain (0x38) if not already connected
          await requestAccounts('0x38');
        }
        
        const etherProvider = new ethers.providers.Web3Provider(provider);
        const signer = etherProvider.getSigner(account);
        const DCLCContract = new ethers.Contract(DCLCAddress, DCLCAbi, signer);
        const balance = await DCLCContract.balanceOf(account); // Use user's account
        const bal = ethers.utils.formatEther(balance);
        setTokenBalance((Number(bal)).toFixed(2));
        setDclcPoints((Number(bal) * 0.01).toFixed(2));
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (connectedWallets && connectedWallets.length > 0 && account) {
      fetchData();
    }
  }, [account, wallet, ChainID, connectedWallets, connecting]);

  return (
    <div className='min-h-screen bg-gray-50 flex flex-col items-center '>
      {/* Header Section */}
      

      {/* Main Content Section */}
      <main className='flex-grow w-full max-w-4xl p-6 '>
        {/* Crypto Allocation Section */}
        <section className='mb-8'>
          <h2 className='text-2xl font-semibold text-gray-700 mb-4'>DCLC Allocation</h2>
          <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
            {/* Token Balance Display */}
            <div className='p-4 flex bg-gray-100 justify-between items-center rounded-lg shadow-md'>
                <div>
              <h3 className='text-lg font-medium'>Token Balance</h3>
              <p className='text-gray-600 text-xl'>{tokenBalance !== 0 ? `${tokenBalance} DCLC` : 'Loading...'}</p>
                </div>
                <div>
                <div className=' flex justify-end items-center'>
      <button 
          onClick={() => requestAccounts('0x38')} 
          className='bg-gradient-to-r from-blue-500 to-blue-700 px-6 py-2 text-white font-semibold text-xs md:text-base rounded-lg shadow-lg'
        >
          {account ? `${account.slice(0, 6)}...${account.slice(-4)}` : "Connect Wallet"}
        </button> 
      </div>
                </div>
            </div>

            {/* DCLC Points for Token Allocation Display */}
            <div className='p-4 bg-gray-100 rounded-lg shadow-md'>
              <h3 className='text-lg font-medium'>DCLC Points</h3>
              <p className='text-gray-600 text-xl'>{dclcPoints !== null ? `${dclcPoints} Points (1% of Holdings)` : 'Loading...'}</p>
            </div>
          </div>
          <p className='mt-4 text-gray-600 md:rounded-lg md:shadow-md md:px-4 md:py-2 md:bg-gray-100 md:font-bold'>The snapshot will be taken on October 11th 2024. After the Token Generation Event (TGE), DCLC points will be converted to tokens.</p>
          <div className='flex justify-evenly mt-6'>
          <button className='text-sm md:text-base bg-gradient-to-tr from-green-400 to-green-600 px-8 py-2 text-white rounded-lg shadow-md' onClick={() => window.open('https://gra.fun/bsc-token/0x9403446d32e54ce62c30dd27e2a1f8667f472537?ref=X8jOFbvsAW1CIg2Nvn5KycyCnmU')}>
          $DCLC Presale 
          </button>
          <button className='text-sm md:text-base bg-gradient-to-tr from-blue-400 to-blue-600 px-8 py-2 text-white rounded-lg shadow-md' onClick={() => window.open('https://t.me/herewalletbot/grafun?startapp=X8jOFbvsAW1CIg2Nvn5KycyCnmU')}>
          <i className="fab fa-telegram"></i> Telegram Bot
          </button>
          </div>
        </section>

        <section>
            <img className='w-full rounded-lg' src={'https://firebasestorage.googleapis.com/v0/b/dailycashloot.appspot.com/o/banners%2FGRA-banner.jfif?alt=media&token=6b1153ab-888d-40e6-952c-431b36f73d3f'} alt='gra-gra' />
        </section>

        {/* GRA Fair Bonding Curve Section */}
        <section className='mb-8'>
          <h2 className='text-2xl font-semibold text-gray-700 mb-4 mt-6'>GRA Fair Bonding Curve</h2>
          <p className='text-gray-600 mb-2'>
            The GRA Fair Bonding Curve is designed to facilitate a transparent and equitable mechanism for buying and selling tokens.
          </p>
          <p className='text-gray-600 mb-2'>
            As more tokens are purchased, the price increases due to supply and demand dynamics. This rewards early supporters by offering tokens at a lower initial price.
          </p>
          <p className='text-gray-600 mb-2'>
            Users can sell tokens back to the curve, promoting liquidity and helping to stabilize token prices over time.
          </p>
        </section>

        {/* DAO Explanation Section */}
        <section className='mb-8'>
          <h2 className='text-2xl font-semibold text-gray-700 mb-4'>What is a DAO?</h2>
          <p className='text-gray-600 mb-2'>
            A Decentralized Autonomous Organization (DAO) is governed by smart contracts, enabling members to participate in decision-making through voting mechanisms.
          </p>
          <p className='text-gray-600 mb-2'>
            DAOs operate without centralized control, providing transparency and allowing stakeholders to propose and vote on initiatives or changes.
          </p>
          <p className='text-gray-600 mb-2'>
            This democratic approach fosters community involvement, ensuring that every member has a voice in the future of the organization.
          </p>
        </section>

        {/* Benefits of Buying and Holding DCLC Token Section */}
        <section className='mb-8'>
          <h2 className='text-2xl font-semibold text-gray-700 mb-4'>Benefits of Buying and Holding DCLC Token</h2>
          <ul className='list-disc list-inside text-gray-600 mb-2'>
            <li>Potential for significant price appreciation as the project expands.</li>
            <li>Eligibility to earn DCLC points for future token allocations, increasing your holdings.</li>
            <li>Participate in governance decisions through DAO voting, shaping the project's future.</li>
            <li>Access exclusive features and benefits within the ecosystem, enhancing user experience.</li>
            <li>Contribute to the sustainability and growth of the project through your investment.</li>
          </ul>
        </section>
      </main>
    </div>
  );
};

export default ClaimPage;
